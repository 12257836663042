import React from "react";
import { PrivacyPolicy } from "../screens/PrivacyPolicy/PrivacyPolicy";
import { TermsOfService } from "../screens/TermsOfService/TermsOfService";
// import { HowToUse } from "../screens/HowToUse/HowToUse";
import { Route, Routes } from "react-router-dom";
import App from "../App";

const Router = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/*" element={<App />} />
        <Route exact path="/TermsOfService" element={<TermsOfService />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        {/* <Route exact path="/HowToUse" element={<HowToUse />} /> */}
      </Routes>
    </div>
  );
};

export default Router;
