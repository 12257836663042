import React, { useEffect, useState } from 'react'
import { Loader, Modal, ModalContent, ModalFooterButtons } from 'monday-ui-react-core'
import mondaySdk from 'monday-sdk-js'

import {
  changeWorkerValue,
  createColumnInBoard,
  createColumnInBoardWithDefaults,
  createNewFolder,
  createNewItem,
  deleteTimesheetLog,
  getAllItemsOnBoard,
  getAllUsers,
  getBoardColumns,
  getBoardsFromFolder,
  getFolders,
  getItemById,
  getItemColumnValues,
  getStatusValues,
  updateMultipleColumnValues,
  updateSimpleColumnValueById,
} from '../../services/Monday.service'
import {
  checkAndReturnColumnObject,
  checkAndSetPersonAsBoardOwner,
  checkAndSetPersonAsWorkspaceOwner,
  checkForNewStatuses,
  dataFromLocalStorage,
  formatDateForInput,
  formatDateMonday,
  getHeaderWidth,
  handleCreateBoardAndColumns,
  mapNewColumnValues,
  setDefaultObjectValues,
  validateForm,
} from '../../util/utils'
import 'monday-ui-react-core/dist/main.css'
import { useContextData } from '../../context/mondayContext'
import {
  defaultStatusColumns,
  isNumberReggex,
  itemDefaultColumns,
  localStorageKeyValues,
  timesheetColumns,
} from '../../util/constants'
import TimesheetItemsTable from './TimesheetItemsTable'
import './TimesheetItemsSection.styles.css'

const monday = mondaySdk()
monday.setApiVersion('2024-01')

const TimesheetItemsSection = ({
  selectedWorkspaces,
  currentWeek,
  dateRange,
  setTotalHours,
  createItem,
  setCreateItem,
  clickedRow,
  setClickedRow,
  newRowData,
  setColumns,
  columns,
  initialTimesheetItems,
  setIsFetching,
  setIsAddingItem,
  isAddingItem,
  setShowSuccess,
  isLoading,
  refreshData,
  setRefreshData,
}) => {
  const [editIndex, setEditIndex] = useState(-1)
  const [timesheetItems, setTimesheetItems] = useState([])
  const [mappedItems, setMappedItems] = useState([])
  const [editData, setEditData] = useState([])
  const [users, setUsers] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [statuses, setStatuses] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSavingEdit, setIsSavingEdit] = useState(false)
  const contextData = useContextData()

  useEffect(() => {
    handleGetUsers()
    // setting initial timesheet items saved in localstorage and getting fresh data
    setTimesheetItems(initialTimesheetItems)
  }, [])

  useEffect(() => {
    if (refreshData) {
      handleGetTimesheetBoards()
    }
  }, [refreshData])

  useEffect(() => {
    if (timesheetItems.length && !createItem) {
      handleSetHeadersAndColumn()
    }
  }, [timesheetItems, createItem])

  useEffect(() => {
    if (isLoading) {
      setLoadingData(true)
    }
  }, [isLoading])

  useEffect(() => {
    if (newRowData.length) {
      setEditData(newRowData)
    }
  }, [newRowData])

  useEffect(() => {
    setTimesheetItems([])
    setMappedItems([])
    setEditData([])
  }, [selectedWorkspaces])

  useEffect(() => {
    if (createItem) {
      handleNewLog()
    } else {
      setEditData([])
      setEditIndex(-1)
    }
  }, [createItem, clickedRow])

  useEffect(() => {
    handleDateFilterChange()
  }, [currentWeek, dateRange])

  useEffect(() => {
    // use data from localstorage ( preload )
    if (!timesheetItems?.length && initialTimesheetItems?.length) {
      setTimesheetItems(initialTimesheetItems)
    } else if (!initialTimesheetItems.length) {
      setLoadingData(false)
    }
  }, [initialTimesheetItems])

  const handleDateFilterChange = async () => {
    setEditData([])
    setEditIndex(-1)
    if (columns?.length) {
      setLoadingData(true)
      await handleMapAllItems(columns)
      setLoadingData(false)
    }
  }

  const handleGetUsers = async () => {
    const resp = await getAllUsers()
    const usersData = resp.map((user) => ({
      value: user?.id,
      label: user?.name,
      leftAvatar: user?.photo_thumb_small,
      email: user?.email,
    }))
    setUsers(usersData)
  }

  const handleGetTimesheetBoards = async () => {
    setIsFetching(true)
    const userData = users.find((user) => parseInt(user.value) === parseInt(contextData.user.id))
    const userEmail = userData?.email
    const workspaceIds = selectedWorkspaces?.map((workspace) => workspace?.value)
    const boards = []
    if (workspaceIds?.length) {
      for (const workspaceId of workspaceIds) {
        const workspaceFolders = await getFolders(workspaceId)
        const workspaceFolderFound = workspaceFolders.find((folder) => folder.name === 'Timesheet')
        if (workspaceFolderFound) {
          const res = await getBoardsFromFolder(workspaceFolderFound.id)
          if (res.length && res[0]?.children) {
            boards.push(res[0].children)
          }
        }
      }
      if (boards?.flat().length) {
        const itemValues = []
        for (const board of boards.flat()) {
          const boardItems = await getAllItemsOnBoard(board.id)
          if (boardItems?.length) {
            boardItems?.forEach((item) => {
              const dateColumn = item.column_values.find(
                (col) => col?.type === 'date' && col?.column?.title === 'Work Date',
              )
              if (dateColumn) {
                const newDate = formatDateMonday(dateColumn?.text)
                dateColumn.text = newDate
              }
              const emailColumn = item.column_values.find((col) => col.column.title === 'Email')
              if (emailColumn && emailColumn?.value) {
                const emailColumnValue = JSON.parse(emailColumn.value)?.email
                if (emailColumnValue?.length) {
                  if (emailColumnValue === userEmail) {
                    itemValues.push(item)
                  }
                }
              }
            })
          }
        }
        if (!itemValues.length) {
          setMappedItems([])
        }
        if (!itemValues.length) {
          setMappedItems([])
        }
        const localstorageData = dataFromLocalStorage(contextData.account.id)
        localStorage.setItem(
          contextData.account.id,
          JSON.stringify({ ...localstorageData, savedTimesheetItems: [...itemValues] }),
        )
        setTimesheetItems(itemValues)
      } else {
        const localstorageData = dataFromLocalStorage(contextData.account.id)
        localStorage.setItem(
          contextData.account.id,
          JSON.stringify({ ...localstorageData, savedTimesheetItems: [] }),
        )
        setTimesheetItems([])
        setMappedItems([])
      }
    }
    if (refreshData && !boards.flat().length) {
      localStorage.removeItem(localStorageKeyValues.savedTimesheetItems)
      setTimesheetItems([])
    }
    setIsFetching(false)
    setRefreshData(false)
  }

  const handleMapAllItems = async (columnsData) => {
    const startDate = dateRange.startDate || currentWeek.startDate
    const endDate = dateRange.endDate || currentWeek.endDate

    let tempTotalHours = 0

    let timesheetItemsData = timesheetItems

    // use preloaded data from localstorage until syncing is done
    if (!timesheetItems?.length && initialTimesheetItems?.length) {
      timesheetItemsData = initialTimesheetItems
    }

    let boardIds = []
    const updatedBoards = await timesheetItemsData.reduce((acc, item) => {
      boardIds.push(item?.board?.id || item?.boardId)
      const workDate = item.column_values.find((col) => col?.column?.title === 'Work Date')
      const workDateValue = new Date(workDate?.text.replace(/-/g, '/')).setHours(0, 0, 0, 0)
      const startDateValue = new Date(startDate).setHours(0, 0, 0, 0)
      const endDateValue = new Date(endDate).setHours(0, 0, 0, 0)
      if (workDate && workDateValue >= startDateValue && workDateValue <= endDateValue) {
        const actualHours = item.column_values.find(
          (col) =>
            col?.column?.title === 'Actual Hours' || col?.column?.title === 'Block Work Time',
        )
        if (actualHours && actualHours.text) {
          tempTotalHours += parseFloat(actualHours.text)
        }

        const updatedColumnValues = columnsData?.map((column) => {
          if (column.title === 'Subitems') {
            return null
          }
          if (column.title === 'Board Name') {
            return {
              column: { title: column.title },
              value: item.boardName,
              text: item.boardName,
            }
          }
          if (column.title === 'Name') {
            return {
              column: { title: column.title },
              value: item.name,
              text: item.name,
            }
          }
          if (column.title === 'Worker') {
            const workerData = item.column_values.find(
              (col) => col?.column?.title === 'Worker' && col?.type === 'text',
            )
            if (workerData) {
              return {
                column: { title: column.title },
                value: workerData.text,
                text: workerData.text,
                type: 'text',
              }
            }
          }
          if (column.type === 'people') {
            return null
          }
          if (column.title === 'Block Work Time' || column.title === 'Actual Hours') {
            const columnFound = item.column_values.find(
              (col) =>
                col?.column?.title === 'Block Work Time' || col?.column?.title === 'Actual Hours',
            )
            return {
              column: { title: 'Block Work Time' },
              value: columnFound?.text || 0,
              text: columnFound?.text || 0,
              type: 'number',
              id: columnFound?.id,
            }
          }
          if (column.title === 'Work Time Notes' || column.title === 'Notes') {
            const columnFound = item.column_values.find(
              (col) => col?.column?.title === 'Work Time Notes' || col?.column?.title === 'Notes',
            )
            return {
              column: { title: 'Work Time Notes' },
              value: columnFound?.text || '',
              text: columnFound?.text || '',
              type: 'text',
              id: columnFound?.id,
            }
          }

          let columnExists = item.column_values.find((col) => col?.column?.title === column.title)

          return !columnExists || columnExists === undefined
            ? {
                column: { title: column.title },
                value: '',
                text: '',
                columnNotFound: true,
              }
            : columnExists
        })

        acc.push({
          ...item,
          column_values: updatedColumnValues?.filter((col) => col !== null),
        })
      }

      return acc
    }, [])

    if (!statuses.length) {
      const boardId = contextData.boardId
      const settingsStrValues = await getStatusValues(boardId)
      const newStatus1 = checkForNewStatuses(
        statuses,
        boardId,
        settingsStrValues,
        defaultStatusColumns.status1,
      )
      const newStatus2 = checkForNewStatuses(
        statuses,
        boardId,
        settingsStrValues,
        defaultStatusColumns.status2,
      )
      if (newStatus1) {
        setStatuses((prevState) => {
          const newData = [...prevState]
          newData.push(newStatus1)
          return [...newData]
        })
      }
      if (newStatus2) {
        setStatuses((prevState) => {
          const newData = [...prevState]
          newData.push(newStatus2)
          return [...newData]
        })
      }
    }

    setTotalHours(tempTotalHours)

    if (editData.length && editIndex === 0) {
      const items = editData.map((item) => {
        if (item.title === 'Email') {
          const userFound = users.find((user) => user.value === item?.value)
          let email
          if (userFound) {
            email = userFound.email
          }
          return {
            column: { title: item.title },
            value: item.value,
            text: email,
          }
        }

        return {
          column: { title: item.title },
          options: item.options,
          value: item.value,
          text: item.value,
        }
      })
      items.unshift({
        column: { title: 'Edit' },
        text: '',
        val: '',
      })
      updatedBoards.unshift({ column_values: [...items] })
    }
    setMappedItems(updatedBoards)
  }

  const handleSetHeadersAndColumn = async (isCreate) => {
    let columnValues = columns
    if (!columnValues.length) {
      columnValues = timesheetColumns.map((col) => ({
        id: col.id,
        title: col.title,
        width: getHeaderWidth(col.type),
        type: col.type,
      }))
    }
    const filtered = columnValues.filter((val) => val !== null)
    const columnMap = new Map(filtered?.map((obj) => [obj?.title, obj]))
    const columnsData = [...columnMap.values()]
    const editColumn = columnsData.find((col) => col.id === 'edit')
    if (!editColumn) {
      columnsData.unshift({ id: 'edit', title: 'Edit', width: 100 })
    }
    setTotalHours(0)

    await handleMapAllItems(columnsData)
    setColumns(columnsData)
    if (!isCreate) {
      setLoadingData(false)
    }
    return columnsData
  }

  const handleNewLog = async () => {
    setLoadingData(true)
    await handleAddEmptyRow()
  }

  const handleAddEmptyRow = async () => {
    let columnsList = columns
    let itemId = null
    const boardId = clickedRow[0]?.boardId || contextData.boardId
    const nameColumn = clickedRow.find((col) => col.id.toLowerCase() === 'name')
    if (clickedRow.length > 0) {
      const clickedRowColumns = await getItemColumnValues(nameColumn.itemId)
      const status1Column = clickedRowColumns[0].column_values.find(
        (col) => col.column.title === defaultStatusColumns.status1,
      )
      const status2Column = clickedRowColumns[0].column_values.find(
        (col) => col.column.title === defaultStatusColumns.status2,
      )
      let savedStatus1
      let savedStatus2
      if (status1Column) {
        savedStatus1 = statuses.find(
          (status) => status.boardId === boardId && status1Column.id === status.columnId,
        )
      }
      if (status2Column) {
        savedStatus2 = statuses.find(
          (status) => status.boardId === boardId && status2Column.id === status.columnId,
        )
      }
      if (!savedStatus1 || !savedStatus2) {
        const settingsStrValues = await getStatusValues(boardId)
        const newStatus1 = checkForNewStatuses(
          statuses,
          boardId,
          settingsStrValues,
          defaultStatusColumns.status1,
        )
        const newStatus2 = checkForNewStatuses(
          statuses,
          boardId,
          settingsStrValues,
          defaultStatusColumns.status2,
        )
        if (newStatus1) {
          setStatuses((prevState) => {
            const newData = [...prevState]
            newData.push(newStatus1)
            return [...newData]
          })
        }
        if (newStatus2) {
          setStatuses((prevState) => {
            const newData = [...prevState]
            newData.push(newStatus2)
            return [...newData]
          })
        }
      }
    }

    const boardColumns = await getBoardColumns(boardId)
    const settingsStrValues = await getStatusValues(boardId)
    if (!clickedRow.length) {
      setEditData([])

      columnsList = await handleSetHeadersAndColumn(true)
    }

    const itemValue = await getItemById(contextData.itemId)
    if (!itemId) {
      itemId = contextData.itemId
    }
    const itemValues = await getItemColumnValues(itemId)
    let status1Val = ''
    let status2Val = ''
    if (itemValues?.length) {
      const status1Column = itemValues[0].column_values.find(
        (col) => col.column.title === defaultStatusColumns.status1,
      )
      const status2Column = itemValues[0].column_values.find(
        (col) => col.column.title === defaultStatusColumns.status2,
      )
      if (status1Column && status1Column.text) {
        status1Val = status1Column.text
      }
      if (status2Column && status2Column.text) {
        status2Val = status2Column.text
      }
    }
    if (!columns.length) {
      const newColumns = await handleSetHeadersAndColumn(true)
      columnsList = newColumns
    }

    const emailFound = columnsList.find((col) => col.title === 'Email')
    if (!emailFound) {
      columnsList.push({
        id: 'email',
        title: 'Email',
        width: getHeaderWidth('email'),
        type: 'email',
      })
    }
    const newItemEmptyValues = columnsList.map((column) => {
      if (!column.title || column.title === 'Edit') {
        return null
      }
      if (column.title === 'Board Name') {
        const clickedRowBoardName = clickedRow?.find((row) => row.title === 'Board Name')
        return {
          column: { title: column.title },
          value: boardColumns?.id,
          text: clickedRowBoardName ? clickedRowBoardName.value : boardColumns[0].name,
          type: '',
          columnId: '',
        }
      }
      if (column.title === 'Name') {
        return checkAndReturnColumnObject(column, clickedRow, itemValue, contextData, 'Name')
      }
      if (column.title === 'Actual Hours') {
        return checkAndReturnColumnObject(
          column,
          clickedRow,
          itemValue,
          contextData,
          'Actual Hours',
        )
      }
      if (column.title === 'Worker' && column.type === 'text') {
        return checkAndReturnColumnObject(column, clickedRow, itemValue, contextData, 'Worker')
      }
      if (column.title === 'Notes') {
        return checkAndReturnColumnObject(column, clickedRow, itemValue, contextData, 'Notes')
      }

      if (column.title === 'Project ID') {
        const clickedRowProjectID = clickedRow?.find((row) => row.title === 'Project ID')
        return {
          column: { title: column.title },
          value: contextData.itemId,
          text: clickedRowProjectID ? clickedRowProjectID.value : column.text,
          type: '',
          columnId: '',
        }
      }

      const columnTitle =
        column.title === 'Actual Hours'
          ? 'Block Work Time'
          : column.title === 'Notes'
            ? 'Work Time Notes'
            : column.title
      const columnFound = boardColumns[0]?.columns?.find((col) => col.title === columnTitle)
      return {
        column: { title: column.title },
        value: '',
        text:
          columnFound?.title === defaultStatusColumns.status1
            ? status1Val
            : columnFound?.title === defaultStatusColumns.status2
              ? status2Val
              : '',
        type: columnFound?.type || '',
        columnId: columnFound?.id || '',
        // columnNotFound: columnFound || column.title === "Email" ? false : true,
      }
    })

    const newStatus1 = checkForNewStatuses(
      statuses,
      boardId,
      settingsStrValues,
      defaultStatusColumns.status1,
    )
    const newStatus2 = checkForNewStatuses(
      statuses,
      boardId,
      settingsStrValues,
      defaultStatusColumns.status2,
    )

    if (newStatus1) {
      setStatuses((prevState) => {
        const newData = [...prevState]
        newData.push(newStatus1)
        return [...newData]
      })
    }
    if (newStatus2) {
      setStatuses((prevState) => {
        const newData = [...prevState]
        newData.push(newStatus2)
        return [...newData]
      })
    }
    setMappedItems((prevState) => {
      const newData = [...prevState]
      newData.unshift({
        boardId: boardId.toString(),
        column_values: [...newItemEmptyValues],
      })
      return [...newData]
    })

    const name = newItemEmptyValues.find((col) => col?.column?.title === 'Name')
    const projectID = newItemEmptyValues.find((col) => col?.column?.title === 'Project ID')
    const workspaceId = name?.workspaceId

    const editDataDefaultValues = []
    timesheetColumns.forEach((value) => {
      const column = newItemEmptyValues.find((col) => col?.column?.title === value.title)
      let defaultValue
      let columnId
      let itemId = ''
      if (value.title === 'Board Name') {
        defaultValue = column.value || column.text
        columnId = column.columnId
      } else if (value.title === 'Name') {
        itemId = column.itemId || column.value
        defaultValue = column.text || column.value
        columnId = column.itemId || column.value
      } else if (value.title === 'Worker') {
        defaultValue = contextData.user.id
        columnId = column.columnId
      } else if (value.title === 'Email') {
        defaultValue = contextData.user.id
        columnId = column.columnId || 'email'
      } else if (value.title === 'Work Date') {
        defaultValue = formatDateForInput(new Date())
        columnId = column.columnId
      } else if (value.title === defaultStatusColumns.status1) {
        defaultValue = status1Val
        columnId = column.columnId
      } else if (value.title === defaultStatusColumns.status2) {
        defaultValue = status2Val
        columnId = column.columnId
      } else {
        defaultValue = ''
        columnId = column.columnId
      }
      const objectValue = setDefaultObjectValues(
        defaultValue,
        boardId,
        value.title,
        value.type,
        columnId,
        itemId,
        workspaceId,
      )
      editDataDefaultValues.push(objectValue)
    })
    if (projectID) {
      const val = setDefaultObjectValues(
        projectID.value,
        boardId,
        'Project ID',
        'text',
        projectID.columnId,
        workspaceId,
      )
      editDataDefaultValues.push(val)
    }
    setEditData(editDataDefaultValues)
    setEditIndex(0)
    setLoadingData(false)
  }

  const handleSaveEdit = async () => {
    const columnValues = {}
    const items = [...mappedItems]
    if (!editData.length) {
      return
    }
    const blockWorkTime = editData.find((val) => val.title === 'Block Work Time')
    if (blockWorkTime && !isNumberReggex.test(blockWorkTime.value)) {
      setErrorMessage({
        title: 'Error',
        message: 'Block Work Time value is invalid',
      })
      return
    }
    setIsSavingEdit(true)
    const itemFound = items.find((val) => val.id === editData[0].itemId)
    for (const editValue of editData) {
      if (editValue?.boardId) {
        const userId = contextData.user.id
        const workspaceId = editValue.workspaceId
        await checkAndSetPersonAsBoardOwner(editValue.boardId, userId)
        await checkAndSetPersonAsWorkspaceOwner(userId, workspaceId)
      }
      const columnFound =
        itemFound &&
        itemFound.column_values.find((item) => {
          let title = item.column.title
          if (title === 'Notes') {
            title = 'Work Time Notes'
          } else if (title === 'Actual Hours') {
            title = 'Block Work Time'
          }
          if (title === editValue.title) {
            return item
          }
        })

      if (editValue.title === 'Block Work Time' && columnFound) {
        const totalHoursAdd = parseFloat(editValue.value) - parseFloat(columnFound.value)
        setTotalHours((prevState) => (prevState += totalHoursAdd))
        columnFound.value = editValue.value
      }

      if (editValue.type === 'status') {
        await updateSimpleColumnValueById(
          editValue.boardId,
          editValue.itemId,
          editValue.columnId,
          editValue.value,
        )
        if (columnFound) {
          columnFound.text = editValue.value
          columnFound.value = editValue.value
        }
      } else {
        if (editValue.title === 'Worker') {
          const workerData = JSON.stringify({
            personsAndTeams: [
              {
                id: editValue.value.value,
                kind: 'person',
              },
            ],
          })
          if (columnFound) {
            columnFound.text = editValue.value.label
            columnFound.value = editValue.value.label
          }
          await changeWorkerValue(
            editData[0].boardId,
            editData[0].itemId,
            editValue.columnId,
            workerData,
          )
        } else if (editValue.title === 'Email') {
          const workerEmail = JSON.stringify({
            email: editValue.value,
            text: editValue.value,
          })
          await changeWorkerValue(
            editData[0].boardId,
            editData[0].itemId,
            editValue.columnId,
            workerEmail,
          )
        } else {
          if (editValue.value) {
            if (columnFound) {
              columnFound.text = editValue.value
            }
            columnValues[editValue.columnId] = editValue.value
          }
        }
      }
    }

    if (Object.keys(columnValues).length > 0) {
      await updateMultipleColumnValues(editData[0].boardId, editData[0].itemId, columnValues)
    }

    setMappedItems(items)

    let updatedTimesheetItems = timesheetItems
    const timesheetItemFoundIndex = updatedTimesheetItems.findIndex(
      (val) => parseInt(val.id) === parseInt(editData[0].itemId),
    )
    const itemValues = items.find((val) => val.id === editData[0].itemId)
    if (timesheetItemFoundIndex !== -1) {
      updatedTimesheetItems[timesheetItemFoundIndex].column_values = itemValues.column_values
    }
    setTimesheetItems(updatedTimesheetItems)
    const localstorageData = dataFromLocalStorage(contextData.account.id)
    localStorage.setItem(
      contextData.account.id,
      JSON.stringify({ ...localstorageData, savedTimesheetItems: [...updatedTimesheetItems] }),
    )

    setIsSavingEdit(false)
    setEditIndex(-1)
  }

  const handleEditButton = async (index) => {
    if (editIndex === 0) {
      handleCancelAddItem()
    }
    const status1 = mappedItems[index].column_values.find(
      (col) => col.column.title === defaultStatusColumns.status1,
    )
    const status2 = mappedItems[index].column_values.find(
      (col) => col.column.title === defaultStatusColumns.status2,
    )
    const status1Found = statuses.find(
      (val) => val.boardId === mappedItems[index].boardId.toString() && val.columnId === status1.id,
    )
    const status2Found = statuses.find(
      (val) => val.boardId === mappedItems[index].boardId.toString() && val.columnId === status2.id,
    )
    if (!status1Found || !status2Found) {
      setLoadingData(true)
      const boardId = mappedItems[index].boardId
      const settingsStrValues = await getStatusValues(boardId)
      const newStatus1 = checkForNewStatuses(
        statuses,
        boardId,
        settingsStrValues,
        defaultStatusColumns.status1,
      )
      const newStatus2 = checkForNewStatuses(
        statuses,
        boardId,
        settingsStrValues,
        defaultStatusColumns.status2,
      )
      if (newStatus1) {
        setStatuses((prevState) => {
          const newData = [...prevState]
          newData.push(newStatus1)
          return [...newData]
        })
      }
      if (newStatus2) {
        setStatuses((prevState) => {
          const newData = [...prevState]
          newData.push(newStatus2)
          return [...newData]
        })
      }
      setLoadingData(false)
    }
    setEditData([])
    setEditIndex(index)
  }

  const handleInputChange = (value, index, column, item) => {
    const columnParsed = JSON.parse(item[column?.title])
    const columnId = columnParsed?.columnId

    const columnTitle = column.title
    const data = mappedItems[index]
    const dataFound = data.column_values.find((val) => val?.column?.title === columnTitle)
    if (dataFound) {
      dataFound.text = value
    }
    const tempEditData = editData
    const foundData = tempEditData.find((data) => data?.title === columnTitle)
    if (foundData) {
      foundData.value = value
    } else {
      tempEditData.push({
        value: value,
        title: columnTitle,
        type: column.type,
        boardId: data.boardId,
        columnId: columnId,
        itemId: data.id,
        workspaceId: data?.board?.workspace_id,
      })
    }
    setEditData(tempEditData)
  }

  const handleCancelAddItem = () => {
    if (createItem || clickedRow.length) {
      setLoadingData(true)
      setMappedItems((prevState) => {
        const newData = [...prevState]
        newData.shift()
        return newData
      })
      const localStorageData = dataFromLocalStorage(contextData.account.id)
      const savedTimesheetItems = localStorageData.savedTimesheetItems
      if (savedTimesheetItems) {
        setTimesheetItems(savedTimesheetItems)
      }
    }
    setEditData([])
    setEditIndex(-1)
    setClickedRow([])
    setCreateItem(false)
  }

  const checkForDefaultColumns = async (boardId) => {
    const boardData = await getBoardColumns(boardId)
    const boardColumns = boardData[0].columns
    for (const column of itemDefaultColumns) {
      const columnFound = boardColumns.find(
        (col) => col.title === column.title && col.type === column.type,
      )
      if (!columnFound) {
        await createColumnInBoard(boardId, column.title, column.type)
      }
    }
  }

  const handleAddItem = async () => {
    const errorMessage = validateForm(editData)
    if (errorMessage) {
      setErrorMessage({ title: 'Error', message: errorMessage })
      return
    }

    const blockWorkTime = editData.find((val) => val.title === 'Block Work Time')
    if (!isNumberReggex.test(blockWorkTime.value)) {
      setErrorMessage({
        title: 'Error',
        message: 'Block Work Time value is invalid',
      })
      return
    }
    setLoadingData(true)
    setIsAddingItem(true)

    const name = editData.find((val) => val.title === 'Name')
    const workspaceId = name?.workspaceId
    const workspaceFolders = await getFolders(workspaceId)
    let folderFound = workspaceFolders.find((folder) => folder.name === 'Timesheet')
    if (!folderFound) {
      await checkAndSetPersonAsWorkspaceOwner(contextData.user.id, workspaceId)
      folderFound = await createNewFolder(workspaceId, 'Timesheet')
    }
    let targetBoard

    const itemColumnValues = await getItemColumnValues(name?.itemId)

    if (!itemColumnValues[0]) {
      setErrorMessage({
        title: 'Error',
        message: 'Getting column values for item has failed',
      })
      setIsAddingItem(false)
      setLoadingData(false)
      return
    }
    let itemName = name?.value
    let timesheetBoardName = itemName
    if (!name?.value) {
      const itemData = await getItemById(contextData.itemId)
      if (!itemData) {
        setErrorMessage({
          title: 'Error',
          message: 'Getting item data has failed',
        })
        setIsAddingItem(false)
        setLoadingData(false)
        return
      }
      itemName = itemData?.name
    }
    const columnFound = itemColumnValues[0].column_values.find(
      (col) => col.column.title === 'Project ID',
    )
    if (columnFound && columnFound?.text) {
      const projectItem = await getItemById(columnFound.text)
      if (!projectItem) {
        setErrorMessage({
          title: 'Error',
          message: 'Getting item data has failed for project item',
        })
        setIsAddingItem(false)
        setLoadingData(false)
        return
      }
      timesheetBoardName = projectItem.name
    }
    let newCreatedColumns = []
    if (folderFound) {
      const boardsFromFolder = await getBoardsFromFolder(folderFound.id)
      let boardFound = null
      if (boardsFromFolder.length) {
        boardFound = boardsFromFolder[0].children.find(
          (board) => board.name === `Timesheet - ${timesheetBoardName}`,
        )
      }
      if (boardFound) {
        await checkForDefaultColumns(boardFound.id)
        targetBoard = boardFound
      } else {
        const { createdColumns, board } = await handleCreateBoardAndColumns(
          folderFound.id,
          timesheetBoardName,
          contextData,
          itemDefaultColumns,
          workspaceId,
        )
        targetBoard = board
        newCreatedColumns = createdColumns
      }
    }
    const boardColumnsRes = await getBoardColumns(targetBoard?.id)
    const columnValues = {}
    if (targetBoard?.id) {
      const userId = contextData.user.id
      await checkAndSetPersonAsBoardOwner(targetBoard.id, userId)
      await checkAndSetPersonAsWorkspaceOwner(userId, workspaceId)
    }
    for (const newItemValue of editData) {
      if (newItemValue.title === 'Board Name' || newItemValue.value === 'Name') {
        continue
      }
      if (
        newItemValue.title === defaultStatusColumns.status1 ||
        newItemValue.title === defaultStatusColumns.status2
      ) {
        let columnId = boardColumnsRes[0].columns.find((col) => col.title === newItemValue.title)
        if (!columnId) {
          const defaultStatusesFound = statuses.find(
            (status) =>
              status.columnId === newItemValue.columnId &&
              parseInt(status.boardId) === parseInt(newItemValue.boardId),
          )
          let defaultStatuses
          if (defaultStatusesFound) {
            defaultStatuses = Object.entries(defaultStatusesFound.values).reduce(
              (acc, [key, value]) => {
                const innerKey = Object.keys(value)[0]
                acc[innerKey] = value[innerKey]
                return acc
              },
              {},
            )
          }
          const res = await createColumnInBoardWithDefaults(
            targetBoard.id,
            newItemValue.title,
            'status',
            JSON.stringify({ labels: defaultStatuses }),
          )
          if (!res) {
            setErrorMessage({
              title: 'Error',
              message: 'Creating status column in board has failed',
            })
            setIsAddingItem(false)
            setLoadingData(false)
            return
          }
          columnId = res
        }
        columnValues[columnId.id] = newItemValue.value
      } else if (newItemValue.title === 'Worker') {
        let columnId = null

        if (newCreatedColumns.length) {
          const columnFound = newCreatedColumns.find(
            (col) => col.title === 'Worker' && col.type === 'text',
          )
          if (columnFound) {
            columnId = columnFound.id
          }
        }
        if (!columnId) {
          const worker = boardColumnsRes[0]?.columns?.find(
            (col) => col.title === 'Worker' && col.type === 'text',
          )
          if (worker) {
            columnId = worker.id
          }
        }
        const workerName = users.find(
          (user) => parseInt(user.value) === parseInt(contextData.user.id),
        )
        // const workerData = {
        //   personsAndTeams: [
        //     {
        //       id: contextData.user?.id,
        //       kind: 'person',
        //     },
        //   ],
        // }
        if (columnId) {
          columnValues[columnId] = workerName.label
        }
      } else if (newItemValue.title === 'Email') {
        let columnId = null

        if (newCreatedColumns.length) {
          const columnFound = newCreatedColumns.find((col) => col.title === 'Email')
          if (columnFound) {
            columnId = columnFound.id
          }
        }
        if (!columnId) {
          const email = boardColumnsRes[0]?.columns?.find((col) => col.title === 'Email')
          if (email) {
            columnId = email.id
          }
        }
        if (columnId) {
          columnValues[columnId] = newItemValue.value
        }
        const userFound = users.find((user) => user.value === contextData.user.id)
        if (userFound) {
          const workerEmail = {
            email: userFound.email,
            text: userFound.email,
          }
          columnValues[columnId] = workerEmail
        }
      } else if (newItemValue.title === 'Block Work Time') {
        let columnId = null

        if (newCreatedColumns?.length) {
          const columnFound = newCreatedColumns.find((col) => col.title === 'Actual Hours')
          if (columnFound) {
            columnId = columnFound.id
          }
        }
        if (!columnId) {
          const actualHours = boardColumnsRes[0]?.columns?.find(
            (col) => col.title === 'Actual Hours',
          )
          if (actualHours) {
            columnId = actualHours.id
          }
        }
        if (columnId) {
          columnValues[columnId] = parseFloat(newItemValue.value)
        }
      } else if (newItemValue.title === 'Work Time Notes') {
        let columnId = null

        if (newCreatedColumns.length) {
          const columnFound = newCreatedColumns.find((col) => col.title === 'Notes')
          if (columnFound) {
            columnId = columnFound.id
          }
        }
        if (!columnId) {
          const notes = boardColumnsRes[0]?.columns?.find((col) => col.title === 'Notes')
          if (notes) {
            columnId = notes.id
          }
        }
        if (columnId) {
          columnValues[columnId] = newItemValue.value
        }
      } else {
        let columnId = boardColumnsRes[0]?.columns?.find(
          (col) => col.title === newItemValue.title,
        )?.id
        if (newCreatedColumns.length) {
          const columnFound = newCreatedColumns?.find((col) => col.title === newItemValue.title)
          if (columnFound) {
            columnId = columnFound?.id
          }
        }
        columnValues[columnId] = newItemValue.value
      }
    }

    if (Object.keys(columnValues).length > 0 || itemName) {
      if (itemColumnValues.length) {
        const projectIDColumn = boardColumnsRes[0]?.columns?.find(
          (col) => col.title === 'Project ID',
        )
        const projectIDColumnItem = itemColumnValues[0].column_values.find(
          (col) => col.column.title === 'Project ID',
        )
        if (projectIDColumn) {
          columnValues[projectIDColumn.id] = projectIDColumnItem?.text
        }
      }
      const res = await createNewItem(targetBoard.id, itemName, JSON.stringify(columnValues))
      if (!res?.id) {
        setErrorMessage({
          title: 'Error',
          message: 'Creating new log has failed',
        })
        setIsAddingItem(false)
        setLoadingData(false)
        return
      }
      if (res?.id) {
        const tempData = timesheetItems

        tempData.unshift({
          board: {
            id: targetBoard.id,
            boardName: targetBoard.name,
            workspace_id: workspaceId,
          },
          boardId: targetBoard.id,
          boardName: targetBoard.name,
          column_values: res?.column_values,
          id: res.id,
          name: itemName,
        })
        const localstorageData = dataFromLocalStorage(contextData.account.id)
        localStorage.setItem(
          contextData.account.id,
          JSON.stringify({ ...localstorageData, savedTimesheetItems: [...tempData] }),
        )
        setTimesheetItems(tempData)
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
        }, 2000)
        setIsAddingItem(false)
        const itemIDColumn = boardColumnsRes[0]?.columns?.find((col) => col.title === 'Item ID')
        if (itemIDColumn) {
          await updateSimpleColumnValueById(
            targetBoard.id,
            res.id,
            itemIDColumn.id,
            name.itemId.toString(),
          )
        }
        setEditIndex(-1)
        setEditData([])
        setCreateItem(false)
        setClickedRow([])
      }
    }
    setIsAddingItem(false)
  }

  const handleDeleteLog = (index) => {
    const item = mappedItems[index]
    if (item?.id) {
      setDeleteItemId(item.id)
    }
    setShowConfirmDelete(true)
  }

  const deleteLog = async () => {
    setIsDeleting(true)
    const data = await deleteTimesheetLog(deleteItemId)
    if (!data) {
      setErrorMessage('Deleting Log has failed')
    }
    const items = timesheetItems.filter((item) => item.id !== deleteItemId)
    setTimesheetItems(items)
    const localstorageData = dataFromLocalStorage(contextData.account.id)
    localstorageData.savedTimesheetItems = items
    localStorage.setItem(
      contextData.account.id,
      JSON.stringify({ ...localstorageData, savedTimesheetItems: [...items] }),
    )
    setMappedItems((prevState) => {
      const data = prevState
      const newData = data.filter((item) => item.id !== deleteItemId)
      return newData
    })
    setShowConfirmDelete(false)
    setIsDeleting(false)
  }

  return (
    <div>
      {loadingData ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Loader className="loader-style" size={40} color={Loader.colors.PRIMARY} />
        </div>
      ) : columns.length > 0 ? (
        <TimesheetItemsTable
          tableColumns={columns}
          tableData={mappedItems}
          handleEditButton={handleEditButton}
          editIndex={editIndex}
          handleSaveEdit={handleSaveEdit}
          handleInputChange={handleInputChange}
          users={users}
          handleAddItem={handleAddItem}
          handleCancelAddItem={handleCancelAddItem}
          createItem={createItem}
          contextData={contextData}
          statuses={statuses}
          isAddingItem={isAddingItem}
          handleDeleteLog={handleDeleteLog}
          isSavingEdit={isSavingEdit}
        />
      ) : (
        <div className="no-items">No Timesheet Items to show</div>
      )}
      <Modal
        onClose={() => setErrorMessage('')}
        show={errorMessage}
        title={errorMessage.title || 'Error'}
      >
        <ModalContent>
          <div style={{ fontSize: 18 }}>{errorMessage.message}</div>
        </ModalContent>
        <ModalFooterButtons
          onPrimaryButtonClick={() => setErrorMessage('')}
          primaryButtonText="Close"
        />
      </Modal>
      <Modal
        onClose={() => setShowConfirmDelete(false)}
        show={showConfirmDelete}
        title={'Are you sure you want to delete log?'}
        classNames={{ container: 'modal-style' }}
      >
        <ModalContent>
          <div style={{ fontSize: 18 }}>{errorMessage.message}</div>
        </ModalContent>
        <ModalFooterButtons
          onPrimaryButtonClick={deleteLog}
          onSecondaryButtonClick={() => setShowConfirmDelete(false)}
          primaryButtonText={
            <div>
              {isDeleting ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  Deleting <Loader size={20} />
                </div>
              ) : (
                'Delete'
              )}
            </div>
          }
          secondaryButtonText="Cancel"
          disablePrimaryButton={isDeleting}
        />
      </Modal>
    </div>
  )
}

export default TimesheetItemsSection
