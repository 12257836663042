import mondaySdk from 'monday-sdk-js'
import { sleep } from '../util/utils'
const monday = mondaySdk()
monday.setApiVersion('2024-01')

const SLEEP_TIME = 35

export const requestMondayApi = async (query, variables = {}, retries = 0) => {
  if (retries > 5) throw new Error('Failed too many times')
  try {
    return await monday.api(query, { variables })
  } catch (error) {
    console.log(`Error while requesting monday api, waiting ${SLEEP_TIME} seconds.`)
    console.log(error)
    await sleep(SLEEP_TIME)
    return await requestMondayApi(query, variables, retries + 1)
  }
}

export const getItemById = async (itemId) => {
  try {
    console.log('Getting item data...')
    const query = `
        query ($itemId: [ID!]) {
          items(ids: $itemId) {
            id
            name
          }
        }
      `
    const variables = { itemId }
    const resp = await monday.api(query, { variables })
    return resp.data.items[0]
  } catch (error) {
    console.error(error, 'There was an error fetching the selected item!')
    return
  }
}

export const getAllWorkspaces = async () => {
  try {
    console.log('Getting all workspaces...')

    const workspaces = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting workspaces...', index)
      index++
      const query = `query{
        workspaces(page: ${index}){
          id
          name
        }
      }
  `

      const response = await monday.api(query)
      if (!response.data?.workspaces || response.data?.workspaces?.length === 0) {
        finished = true
      } else {
        response.data.workspaces.forEach((board) => workspaces.push(board))
      }
    }

    return workspaces
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getBoardsFromWorkspaces = async (workspaceIds) => {
  try {
    console.log('Getting boards from workspaces...')

    const boards = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting boards...', index)
      index++
      const query = `query($workspaceIds: [ID!]){
            boards(workspace_ids: $workspaceIds, state: active, page: ${index}){
              id
              name
            }
          }
      `

      const variables = { workspaceIds }
      const response = await monday.api(query, { variables })
      if (!response.data?.boards || response.data?.boards?.length === 0) {
        finished = true
      } else {
        response.data.boards.forEach((board) => boards.push(board))
      }
    }

    return boards
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getItemColumnValues = async (itemIDs) => {
  try {
    console.log('Getting item column values...')

    const items = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting boards...', index)
      index++
      const query = `query($itemIDs: [ID!]){
            items(ids: $itemIDs, page: ${index}){
                board {
                  id
                }
                id
                name
                column_values{
                    id
                    text
                    value
                    type
                    column{
                        title
                    }
                }
            }
          }
      `

      const variables = { itemIDs }
      const response = await monday.api(query, { variables })
      if (!response.data?.items || response.data?.items?.length === 0) {
        finished = true
      } else {
        response.data.items.forEach((item) => items.push(item))
      }
    }

    return items
  } catch (err) {
    console.error(err)
    return []
  }
}

export async function getAllItemsOnBoard(boardId) {
  try {
    console.log('Get all items on board...')
    const allItems = []
    let cursor = null

    do {
      const query = `
      query($boardId: [ID!]){
        boards(ids: $boardId) {
          items_page (limit: 20, ${cursor ? `cursor: "${cursor}"` : ''}) {
            cursor
            items{
                id
                name
                 board {
                  id
                  name
                  workspace_id
                }
                column_values{
                  id
                  text
                  value
                  type
                  column {
                    title
                  }
              }
            }
          }
        }
      }
      `

      const variables = { boardId }
      const response = await monday.api(query, {
        variables,
      })

      if (!response.data) {
        cursor = null
      }

      if (response.data.boards[0].items_page.items.length > 0) {
        cursor = response.data.boards[0].items_page.cursor
        const boardName = response.data.boards[0].items_page.items[0].board.name
        const items = response.data.boards[0].items_page.items
        const itemsData = items?.map((item) => ({
          boardId: boardId,
          boardName: boardName,
          ...item,
        }))
        allItems.push(...itemsData)
      } else {
        cursor = null
      }
    } while (cursor !== null)

    return allItems
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while getting all items on board!'
    }
    throw new Error(message)
  }
}

export async function updateSimpleColumnValueById(boardId, itemId, columnId, simpleValue) {
  try {
    console.log('Updating simple column value...')
    const query = `
      mutation($board_id: ID!, $item_id: ID, $column_id: String!, $value: String!) {
        change_simple_column_value(board_id: $board_id, item_id: $item_id, column_id: $column_id, value: $value, create_labels_if_missing: true){
          id
        }
      }
      `

    const variables = {
      board_id: parseInt(boardId.toString()),
      item_id: parseInt(itemId.toString()),
      column_id: columnId,
      value: simpleValue,
    }

    const response = await requestMondayApi(query, variables)

    if (!response.data) {
      console.error(response)
    }
    return response
  } catch (err) {
    console.error(err)
  }
}

export async function updateMultipleColumnValues(boardId, itemId, columnValues) {
  try {
    console.log('Updating column value...')
    const query = `
      mutation($boardId: ID!, $itemId: ID!, $columnValues: JSON!) {
        change_multiple_column_values(board_id: $boardId, item_id: $itemId, column_values: $columnValues){
          id
        }
      }
      `

    const variables = {
      boardId: parseInt(boardId.toString()),
      itemId: parseInt(itemId.toString()),
      columnValues: JSON.stringify(columnValues),
    }

    const response = await requestMondayApi(query, variables)

    if (!response.data) {
      console.error(response)
    }

    return response.data.change_multiple_column_values.id
  } catch (err) {
    console.error(err)
  }
}

export const getAllUsers = async () => {
  try {
    console.log('Getting users from workspaces...')

    const users = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting users...', index)
      index++
      const query = `query{
              users(page: ${index}){
                id
                name
                photo_thumb_small
                email
              }
            }
        `

      const response = await monday.api(query)
      if (!response.data?.users || response.data?.users?.length === 0) {
        finished = true
      } else {
        response.data.users.forEach((user) => users.push(user))
      }
    }

    return users
  } catch (err) {
    console.error(err)
    return []
  }
}

export async function changeWorkerValue(boardId, itemId, columnId, workerData) {
  try {
    const mutation = `
      mutation($boardId: ID!, $itemId: ID!, $columnId: String!, $workerData: JSON!) {
        change_column_value(item_id: $itemId, column_id: $columnId, board_id: $boardId, value: $workerData){
          id
        }
      }
      `

    const variables = {
      boardId,
      itemId,
      columnId,
      workerData,
    }

    const response = await requestMondayApi(mutation, variables)

    if (!response.data) {
      console.error(response)
    }
    return response.data
  } catch (err) {
    console.error(err)
  }
}

export async function getBoardColumns(boardIds) {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting board columns...', index)
      index++
      const query = `query($boardIds: [ID!]){
        boards(limit: 50, ids: $boardIds, page: ${index}){
          id
          name
          columns{
            id
            title
            type
          }
        }
        }
  `

      const variables = { boardIds }
      const resp = await monday.api(query, { variables })
      if (resp.data.boards.length === 0) {
        finished = true
      } else {
        resp.data.boards.forEach((item) => data.push(item))
      }
    }
    return data
  } catch (err) {
    console.error(err)
  }
}

export async function createNewItem(boardId, itemName, columnValues) {
  try {
    console.log('Creating new Item...')
    const query = `
        mutation($itemName: String!, $boardId: ID!, $columnValues: JSON!){
        create_item(item_name: $itemName, board_id: $boardId, create_labels_if_missing: true, column_values: $columnValues) {
            id
            column_values{
              id
              column { title }
              text
              value
              type
            }
        }
        }
      `

    const variables = { boardId, itemName, columnValues }
    const response = await requestMondayApi(query, variables)
    if (!response.data) {
      console.error(response)
      return null
    }
    return response.data.create_item
  } catch (err) {
    console.error(err)
  }
}

export async function getWorkspaceData(workspaceId) {
  try {
    console.log('Getting Workspace data...')
    const query = `
        query($workspaceId: [ID!]){
        workspaces(ids: $workspaceId) {
            id
            name
        }
        }
      `

    const variables = { workspaceId }
    const response = await monday.api(query, { variables })

    if (!response.data) {
      console.error(response)
    }
    return response.data.workspaces[0]
  } catch (err) {
    console.error(err)
  }
}

export const getFolders = async (workspaceId) => {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting folders...', index)
      index++
      const query = `query($workspaceId: [ID!]){
        folders(limit: 20, workspace_ids: $workspaceId, page: ${index}){
          id
          name
          workspace{
            id
            name
          }
        }
        }
  `

      const variables = { workspaceId }
      const resp = await monday.api(query, { variables })
      if (resp.data.folders.length === 0) {
        finished = true
      } else {
        resp.data.folders.forEach((item) => data.push(item))
      }
    }
    return data
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getBoardsFromFolder = async (folderIds) => {
  try {
    console.log('Getting boards from folder...')
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting folders...', index)
      index++
      const query = `query($folderIds: [ID!]){
          folders(limit: 20, ids: $folderIds, page: ${index}){
              id
              name
              children{
                id
                name
                state
              }
          }
        }
    `

      const variables = { folderIds }
      const resp = await monday.api(query, { variables })
      if (resp.data.folders.length === 0) {
        finished = true
      } else {
        resp.data.folders.forEach((item) => data.push(item))
      }
    }

    return data
  } catch (err) {
    console.log('An error occurred while getting boards from folder!')
    console.error(err)
    throw new Error(err)
  }
}

export const getStatusValues = async (boardId) => {
  try {
    console.log('Getting boards column settings_str...')
    const query = `query($boardId: [ID!]){
          boards(ids: $boardId){
            columns{
              id
              title
              type
              settings_str
            }
          }
        }
    `

    const variables = { boardId }
    const resp = await monday.api(query, { variables })

    if (!resp.data) {
      console.error(resp)
    }

    return resp.data.boards[0]
  } catch (err) {
    console.log('An error occurred while getting board columns!')
    console.error(err)
    throw new Error(err)
  }
}

export async function createNewBoard(workspaceId, folderId, boardName) {
  try {
    console.log('Creating new board...')
    const createItem = `
    mutation($boardName: String!, $folderId: ID!, $workspaceId: ID!) {
      create_board(empty: true, board_name: $boardName, folder_id: $folderId, workspace_id: $workspaceId, board_kind: public){
                 id
                 name
                 columns {
                   id
                   title
                 }
             }
      }
    `

    const variables = {
      boardName,
      folderId,
      workspaceId,
    }
    const response = await requestMondayApi(createItem, variables)

    if (!response.data) {
      console.error(response)
    }

    return response.data.create_board
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while creating new board!'
    }
    throw new Error(message)
  }
}
export async function createNewFolder(workspaceId, folderName) {
  try {
    console.log('Creating new folder...')
    const createItem = `
    mutation($folderName: String!, $workspaceId: ID!) {
      create_folder(workspace_id: $workspaceId, name: $folderName){
                 id
                 name
             }
      }
    `

    const variables = {
      workspaceId,
      folderName,
    }
    const response = await requestMondayApi(createItem, variables)

    if (!response.data) {
      console.error(response)
    }

    return response.data.create_folder
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while creating new folder!'
    }
    throw new Error(message)
  }
}

export const createColumnInBoard = async (boardId, title, type) => {
  try {
    console.log('Creating missing column in board')

    const mutation = `mutation ($boardId: ID!, $title: String!, $type: ColumnType!) {
          create_column(board_id: $boardId, title: $title, column_type: $type) {
            id
            title
            type
          }
        }
    `
    const variables = { boardId, title, type }
    const response = await requestMondayApi(mutation, variables)

    if (!response.data) {
      console.error(response)
    }
    return response?.data.create_column
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while creating column in board!'
    }
    throw new Error(message)
  }
}

export const getItemsWithCustomRules = async (boardId, customRules) => {
  try {
    console.log('Getting items with custom rules...')

    const allItems = []
    let cursor = null
    const query = `query ($boardId: [ID!]) {
      complexity {
        query
      }
      boards(ids: $boardId ) {
        items_page(
        limit: 20,
        query_params: {
          ${customRules}
        }) {
        cursor
          items {
            board {
              id
              name
              workspace_id
              }
            id
            name
            column_values {
              id
              text
              value
              type
              column {
                title
                  }
                }
              }
            }
          }
      }`

    const variables = { boardId }
    const response = await monday.api(query, {
      variables,
    })
    if (!response.data) {
      cursor = null
    }

    if (response.data.boards[0].items_page.items.length > 0) {
      cursor = response.data.boards[0].items_page.cursor
      const boardName = response.data.boards[0].items_page.items[0].board.name
      const items = response.data.boards[0].items_page.items
      const itemsData = items?.map((item) => ({
        boardId: boardId,
        boardName: boardName,
        ...item,
      }))
      allItems.push(...itemsData)
    } else {
      cursor = null
    }

    if (cursor) {
      do {
        const query = `query ($boardId: [ID!]) {
          boards(ids: $boardId ) {
            items_page(limit: 20, ${cursor ? `cursor: "${cursor}"` : ''}
          ) {
              items {
                id
                name
                  board {
                    id
                    name
                    workspace_id
                    }
                column_values {
                  id
                  text
                  value
                  column {
                    title
                          }
                      }
                    }
                }
          }
        }
    `
        const variables = { boardId }
        const response = await monday.api(query, {
          variables,
        })

        if (!response.data) {
          cursor = null
        }

        if (response.data.boards[0].items_page.items.length > 0) {
          cursor = response.data.boards[0].items_page.cursor
          const items = response.data.boards[0].items_page.items
          allItems.push(...items)
        } else {
          cursor = null
        }
      } while (cursor !== null)
    }

    return allItems
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while getting items with custom rules!'
    }
    throw new Error(message)
  }
}

export const createColumnInBoardWithDefaults = async (boardId, title, type, defaultData) => {
  try {
    console.log('Creating missing column in board')

    const mutation = `mutation ($boardId: ID!, $title: String!, $type: ColumnType!, $defaultData: JSON!) {
          create_column(board_id: $boardId, title: $title, column_type: $type, defaults: $defaultData) {
            id
            title
            type
          }
        }
    `
    const variables = { boardId, title, type, defaultData }
    const response = await requestMondayApi(mutation, variables)

    return response?.data.create_column
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while creating column in board with default data!'
    }
    throw new Error(message)
  }
}

export async function deleteTimesheetLog(itemId) {
  try {
    const mutation = `mutation($itemId: ID!){
      delete_item(item_id:$itemId){
        id
      }
    }
     `
    const variables = { itemId }
    const response = await requestMondayApi(mutation, variables)

    console.log('Item deleted successfully')
    return response.data
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while deleting item!'
    }
    throw new Error(message)
  }
}
export async function setBoardOwners(boardId, assignees) {
  try {
    const mutation = `mutation($boardId: ID!, $assignees: [ID!]!){
      add_users_to_board(board_id:$boardId, user_ids:$assignees, kind:owner){
        id
      }
    }
     `
    const variables = { boardId, assignees }
    const response = await requestMondayApi(mutation, variables)

    console.log('Board owners set successfully')
    return response.data
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while setting board owners!'
    }
    throw new Error(message)
  }
}

export async function getBoardOwners(boardId) {
  try {
    const query = `query($boardId: [ID!]){
      boards(ids: $boardId) {
        owners{
          id
          }
      }
    }
     `
    const variables = { boardId }
    const response = await monday.api(query, { variables })

    return response.data.boards[0].owners
  } catch (err) {
    console.error(err)
    let message = err.message
    if (!message) {
      message = 'An error occurred while getting board owners!'
    }
    throw new Error(message)
  }
}

export const getWorkspaceMembers = async (workspaceId) => {
  try {
    console.log('Getting Workspace members')
    const mutation = `query ($workspaceId: [ID!]) {
            workspaces(ids: $workspaceId){
              owners_subscribers{
                id
                name
                }
            }
          }
      `
    const variables = { workspaceId }
    const response = await requestMondayApi(mutation, variables)
    if (!response.data) {
      console.error(response)
      return []
    }
    return response?.data?.workspaces[0]?.owners_subscribers
  } catch (err) {
    console.error(err)
    return []
  }
}
export const addWorkspaceMember = async (workspaceId, userId) => {
  try {
    console.log('Adding Workspace member')
    const mutation = `mutation ($workspaceId: ID!, $userId: [ID!]!) {
            add_users_to_workspace(workspace_id: $workspaceId, user_ids: $userId){
                id
            }
          }
      `
    const variables = { workspaceId, userId }
    const response = await requestMondayApi(mutation, variables)
    if (!response.data) {
      console.error(response)
      return []
    }
    return response?.data?.add_users_to_workspace?.id
  } catch (err) {
    console.error(err)
    return []
  }
}
