import React, { useMemo } from 'react'
import { setColumnMinWidth } from '../../util/utils'
import { useFlexLayout, useResizeColumns, useTable } from 'react-table'
import './AssignedOnItemsSection.styles.css'

const AssignedOnItemsTable = ({
  tableData,
  tableColumns,
  handleClickedRow,
  userAssignedBoards,
}) => {
  const data = useMemo(() => {
    return tableData?.map((dataItem) => {
      const transformedData = dataItem?.column_values?.map((col, index) => {
        return { [tableColumns[index]?.title]: col?.text || '' }
      })
      return Object.assign({}, ...transformedData)
    })
  }, [tableData, tableColumns])

  const columns = useMemo(() => {
    if (tableColumns?.length > 0) {
      return tableColumns?.map((col) => ({
        Header: col.title,
        accessor: col.title,
        minWidth: setColumnMinWidth(col),
        width: col.title === 'Timeline' && 200,
      }))
    }
    return []
  }, [tableColumns])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useResizeColumns,
  )

  const handleRowClick = (rowData, index) => {
    const rowDataItemName = rowData.find((row) => row.column.Header === 'Name')
    let itemId = ''
    if (rowDataItemName) {
      const itemFound = tableData[index]
      if (itemFound) {
        itemId = itemFound.id
      }
    }
    let boardData = {}
    const clickedRowBoard = rowData.find((row) => row.column.Header === 'Board Name')
    if (clickedRowBoard) {
      const boardFound = userAssignedBoards
        .flat()
        .find((val) => val?.board?.name === clickedRowBoard.value)

      if (boardFound) {
        boardData = boardFound.board
      }
    }
    const rowDataValues = rowData?.map((row) => {
      return {
        id: row.column.id,
        title: row.column.Header,
        value: row.value,
        itemId: itemId,
        boardId: boardData.id,
        workspaceId: boardData.workspace_id,
      }
    })
    handleClickedRow(rowDataValues)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance
  return (
    <div className="table-container">
      {userAssignedBoards?.flat()?.length > 0 ? (
        <table
          {...getTableProps()}
          style={{
            borderCollapse: 'collapse',
            maxWidth: '100%',
            overflow: 'hidden',
            marginBottom: 20,
          }}
        >
          {headerGroups?.length > 0 ? (
            <thead>
              {headerGroups?.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps()}
                      {...column.getResizerProps()} // Allow resizing
                      className="header-style"
                      style={{
                        ...column.getHeaderProps().style,
                      }}
                    >
                      {column.render('Header')}
                      <div {...column.getResizerProps()} className="header-column" />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          ) : (
            ''
          )}
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows?.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    className="table-row"
                    onClick={() => handleRowClick(row.cells, i)}
                  >
                    {row?.cells?.map((cell, index) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={tableColumns.length} className="no-items">
                  No items found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="no-items">No items found</div>
      )}
    </div>
  )
}

export default AssignedOnItemsTable
