import {
  addWorkspaceMember,
  createColumnInBoard,
  createNewBoard,
  getBoardColumns,
  getBoardOwners,
  getBoardsFromFolder,
  getFolders,
  getItemsWithCustomRules,
  getWorkspaceMembers,
  setBoardOwners,
} from '../services/Monday.service'
import { defaultStatusColumns } from './constants'

export const getHeaderWidth = (type) => {
  switch (type) {
    case 'numbers':
      return 150
    case 'long_text':
      return 300
    default:
      return 200
  }
}

export const formatDateForInput = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = String(date.getFullYear()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = String(date.getFullYear()).padStart(2, '0')
  return `${month}/${day}/${year}`
}
export const formatDateWeekFilter = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  return `${month}/${day}`
}

export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailPattern.test(email)
}

export const setColumnMinWidth = (column) => {
  if (
    column.title === 'Board Name' ||
    column.title === 'Name' ||
    column.title === 'Worker' ||
    column.title === 'Email' ||
    column.title === 'Notes'
  ) {
    return 250
  }
  if (column.title === 'Work Time Notes') {
    return 350
  }
  if (column.title === 'Edit') {
    return 100
  }
  return 170
}

export const getWeekRange = (date) => {
  const startDate = new Date(date)
  const endDate = new Date(date)

  // Set the start of the week ( Monday )
  startDate.setDate(date.getDate() - date.getDay() + 1)

  // Set the end of the week ( Sunday )
  endDate.setDate(date.getDate() + (7 - date.getDay()))

  return { startDate, endDate }
}
export const getCustomRulesData = async (workspaceId, accountId) => {
  if (workspaceId.length) {
    const folders = await getFolders(workspaceId)
    if (folders.length) {
      const filteredFolders = folders.filter(
        (folder) => folder.name === 'Project Management' || folder.name === 'Task Management',
      )
      const folderIds = filteredFolders?.map((folder) => folder.id)
      const folderBoards = await getBoardsFromFolder(folderIds)
      const boardIDs = []
      const boards = []
      for (const folder of folderBoards) {
        const tempBoardIds = folder.children.map((board) => board.id)
        const tempBoards = folder.children.map((board) => ({
          value: board.id,
          label: board.name,
        }))
        boards.push(tempBoards)
        boardIDs.push(tempBoardIds)
      }

      const localstorageData = dataFromLocalStorage(accountId)
      localStorage.setItem(
        accountId,
        JSON.stringify({ ...localstorageData, savedBoards: boards.flat() }),
      )
      const boardColumns = await getBoardColumns(boardIDs.flat())
      return boardColumns
    }
  }
}

export const getCustomRulesBoardsData = async (boardIds, itemName) => {
  if (itemName) {
    const customRules = `rules: [{
          column_id: "name",
          compare_value: "${itemName}",
          operator: contains_text,
          }]`
    const items = await getItemsWithCustomRules(boardIds, customRules)
    return items
  }
}

export const formatDateMonday = (dateStr) => {
  const [year, month, day] = dateStr.split('-')
  return `${month}-${day}-${year}`
}

export const doTimelinesOverlap = (range1Start, range1End, range2Start, range2End) => {
  const startTimeline = new Date(range1Start).getTime()
  const endTimeline = new Date(range1End).getTime()
  const startFilter = new Date(range2Start).getTime()
  const endFilter = new Date(range2End).getTime()

  if (startFilter >= startTimeline && endFilter <= endTimeline) {
    return true
  } else if (startTimeline >= startFilter && startTimeline <= endFilter) {
    return true
  } else if (endTimeline >= startFilter && endTimeline <= endFilter) {
    return true
  }

  return false
}

export const checkAndReturnColumnObject = (column, clickedRow, itemValue, contextData, title) => {
  if (column.title === title) {
    const clickedRowName = clickedRow?.find((row) => row.title === title)
    return {
      column: { title: column.title },
      value: clickedRowName ? clickedRowName.itemId : contextData.itemId,
      text: clickedRowName ? clickedRowName.value : itemValue.name,
      type: '',
      columnId: '',
      workspaceId: clickedRowName ? clickedRowName?.workspaceId : contextData?.workspaceId,
    }
  }
}

export const checkForNewStatuses = (statuses, boardId, settingsStrValues, statusName) => {
  const statusFound = settingsStrValues?.columns?.find((col) => col.title === statusName)
  const statusSaved =
    statuses?.length > 0 &&
    statuses.find(
      (val) => parseInt(val?.boardId) === parseInt(boardId) && val?.columnId === statusFound?.id,
    )
  if (!statusSaved && statusFound) {
    return {
      columnId: statusFound?.id,
      boardId: boardId?.toString(),
      values: Object.entries(JSON.parse(statusFound?.settings_str)?.labels).map(([key, value]) => ({
        [key]: value,
      })),
    }
  }
  return null
}

export const setDefaultObjectValues = (
  value,
  boardId,
  title,
  type,
  columnId,
  itemId = '',
  workspaceId,
) => {
  return {
    value: value,
    title: title,
    type: type,
    boardId: boardId,
    columnId: columnId,
    itemId: itemId,
    workspaceId: workspaceId,
  }
}

export const mapNewColumnValues = (editData, users, contextData) => {
  return editData.map((val) => {
    if (val.title === 'Worker') {
      const userFound = users.find((user) => user.value === val.value)
      return {
        column: { title: val.title },
        id: val.columnId,
        text: userFound?.label,
        type: val.type,
        value: {
          personsAndTeams: [
            {
              id: contextData.user?.id,
              kind: 'person',
            },
          ],
        },
      }
    }
    if (val.title === 'Email') {
      const userFound = users.find((user) => user.value === val.value)
      return {
        column: { title: val.title },
        id: val.columnId,
        text: userFound?.email,
        type: val.type,
        value: val.value,
      }
    }
    return {
      column: { title: val.title },
      id: val.columnId,
      text: val.value,
      type: val.type,
      value: val.value,
    }
  })
}

export const checkAndSetPersonAsBoardOwner = async (boardId, userId) => {
  const boardOwners = await getBoardOwners(boardId)
  const isBoardOwner = boardOwners.find((owner) => parseInt(owner.id) === parseInt(userId))
  if (!isBoardOwner) {
    await setBoardOwners(boardId, parseInt(userId))
  }
}

export const checkAndSetPersonAsWorkspaceOwner = async (userId, workspaceId) => {
  const workspaceOwners = await getWorkspaceMembers(workspaceId)
  const isWorkspaceOwner = workspaceOwners.find((owner) => parseInt(owner.id) === parseInt(userId))
  if (!isWorkspaceOwner) {
    await addWorkspaceMember(workspaceId, parseInt(userId))
  }
}

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const handleCreateBoardAndColumns = async (
  folderId,
  itemName,
  contextData,
  itemDefaultColumns,
  workspaceId,
) => {
  const res = await createNewBoard(workspaceId, folderId, `Timesheet - ${itemName}`)
  const board = res
  let createdColumns = []
  for (let i = 0; i < itemDefaultColumns.length; i++) {
    const response = await createColumnInBoard(
      board.id,
      itemDefaultColumns[i].title,
      itemDefaultColumns[i].type,
    )

    createdColumns.push(response)
    if (!response) {
      console.log(`Creating ${itemDefaultColumns[i].type} type column in Board has FAILED!`)
    }
  }
  return { createdColumns, board }
}

export const validateForm = (editData) => {
  const blockWorkTimeColumn = editData.find((col) => col?.title === 'Block Work Time')
  if (!blockWorkTimeColumn) {
    return 'Block Work Time column is missing'
  }
  if (!blockWorkTimeColumn?.value) {
    return 'Block Work Time value is required'
  }
  const workDateColumn = editData.find((col) => col?.title === 'Work Date')
  if (!workDateColumn) {
    return 'Work Date column is missing'
  }
  if (!workDateColumn?.value) {
    return 'Work Date value is required'
  }
  const status1Column = editData.find((col) => col?.title === defaultStatusColumns.status1)
  const status2Column = editData.find((col) => col?.title === defaultStatusColumns.status2)
  if (!status1Column?.value && !status2Column?.value) {
    return `${defaultStatusColumns.status1} and ${defaultStatusColumns.status2} are required`
  }
  if (!status1Column?.value) {
    return `${defaultStatusColumns.status1} value is required`
  }
  if (!status2Column?.value) {
    return `${defaultStatusColumns.status2} value is required`
  }
  return ''
}

export const dataFromLocalStorage = (accountId) => {
  const localstorageJson = localStorage.getItem(accountId)
  const localstorageData = JSON.parse(localstorageJson)
  return localstorageData
}
