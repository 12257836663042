import { Button, Chips } from 'monday-ui-react-core'
import { AddSmall, Rotate } from 'monday-ui-react-core/icons'
import React from 'react'
import CustomDateFilter from '../CustomDateFilter/CustomDateFilter'
import WeekCycleFilter from '../WeekCycleFilter/WeekCycleFilter'
import { getWeekRange } from '../../util/utils'
import 'monday-ui-react-core/dist/main.css'
import './Filters.css'
import FetchingMessage from '../common/FetchingMessage'

const Filters = ({
  setTotalHours,
  totalHours,
  dateRange,
  setDateRange,
  setCurrentWeek,
  currentWeek,
  setCreateItem,
  isFetching,
  isAddingItem,
  showSuccess,
  setRefreshData,
}) => {
  const handlePreviousWeek = () => {
    setTotalHours(0)
    setDateRange({ startDate: null, endDate: null })
    const previousWeekStart = new Date(currentWeek.startDate)
    previousWeekStart.setDate(previousWeekStart?.getDate() - 7)
    setCreateItem(false)
    setCurrentWeek(getWeekRange(previousWeekStart))
  }

  const handleNextWeek = () => {
    setTotalHours(0)
    setDateRange({ startDate: null, endDate: null })
    const nextWeekStart = new Date(currentWeek.startDate)
    nextWeekStart.setDate(nextWeekStart?.getDate() + 7)
    setCreateItem(false)
    setCurrentWeek(getWeekRange(nextWeekStart))
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'end',
          textWrap: 'nowrap',
          gap: 16,
        }}
      >
        <Button
          style={{ marginTop: 'auto' }}
          size={Button.sizes.SMALL}
          rightIcon={AddSmall}
          onClick={() => setCreateItem(true)}
          disabled={isAddingItem}
        >
          New Log
        </Button>
        <Button
          style={{ marginTop: 'auto' }}
          size={Button.sizes.SMALL}
          rightIcon={Rotate}
          onClick={() => setRefreshData(true)}
          disabled={isAddingItem}
        >
          Refresh
        </Button>
        {showSuccess && (
          <span className="success-message">
            <Chips
              className="success-chip"
              color={Chips.colors.DONE_GREEN}
              label="Log created successfully"
              readOnly
            />
          </span>
        )}
        {isAddingItem && !isFetching && !showSuccess && (
          <FetchingMessage message="Creating new log" />
        )}
        {isFetching && !isAddingItem && !showSuccess && <FetchingMessage message="Getting items" />}
      </div>
      <div className="filters">
        <CustomDateFilter setDateRange={setDateRange} dateRange={dateRange} />
        <WeekCycleFilter
          handleNextWeek={handleNextWeek}
          handlePreviousWeek={handlePreviousWeek}
          currentWeek={currentWeek}
        />
        <Chips label={`Total hours: ${parseFloat(totalHours).toFixed(2)}`} readOnly />
      </div>
    </div>
  )
}

export default Filters
