import { useEffect, useState } from 'react'
import { Divider, Dropdown } from 'monday-ui-react-core'
import mondaySdk from 'monday-sdk-js'
import 'monday-ui-react-core/dist/main.css'

import './App.css'
import Filters from './components/Filters/Filters'
import TimesheetItemsSection from './components/TimesheetTable/TimesheetItemsSection'
import AssignedOnItemsSection from './components/AssignedOnTable/AssignedOnItemsSection'
import { getAllWorkspaces, getItemColumnValues, getWorkspaceData } from './services/Monday.service'
import { formatDateForInput, getWeekRange } from './util/utils'
import { useContextData } from './context/mondayContext'
import { localStorageKeyValues } from './util/constants'

const monday = mondaySdk()
monday.setApiVersion('2024-01')

let SLUG
const App = () => {
  const [workspaces, setWorkspaces] = useState([])
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([])
  const [totalHours, setTotalHours] = useState(0)
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  })
  const [createItem, setCreateItem] = useState(false)
  const [currentWeek, setCurrentWeek] = useState(getWeekRange(new Date()))
  const [clickedRow, setClickedRow] = useState([])
  const [newRowData, setNewRowData] = useState([])
  const [columns, setColumns] = useState([])
  const [initialTimesheetItems, setInitialTimesheetItems] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [savedBoardsList, setSavedBoardsList] = useState([])
  const [isAddingItem, setIsAddingItem] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [savedAssignedOnItems, setSavedAssignedOnItems] = useState([])
  const [refreshData, setRefreshData] = useState(false)

  const contextData = useContextData()

  const getDataFromStorage = async () => {
    const localstorageJson = localStorage.getItem(contextData.account.id)
    let localstorageData = {}
    if (localstorageJson) {
      localstorageData = JSON.parse(localstorageJson)
    }
    const savedWorkspaces = localstorageData?.savedWorkspaces
    const savedTimesheetItems = localstorageData?.savedTimesheetItems
    const savedAssignedItems = localstorageData?.savedAssignedOnItems
    const savedBoards = localstorageData?.savedBoards

    if (savedTimesheetItems) {
      setInitialTimesheetItems(savedTimesheetItems)
    }
    if (savedBoards) {
      setSavedBoardsList(savedBoards)
    }
    if (savedAssignedItems) {
      setSavedAssignedOnItems(savedAssignedItems)
    }

    const workspaceData = await getWorkspaceData(contextData.workspaceId)
    if (workspaceData && savedWorkspaces?.length) {
      const data = savedWorkspaces || []
      const workspaceFound = data?.find((val) => parseInt(val.value) === contextData.workspaceId)
      if (!workspaceFound) {
        data.push({ value: workspaceData.id, label: workspaceData.name })
        localStorage.setItem(
          contextData.account.id,
          JSON.stringify({ ...localstorageData, savedWorkspaces: data }),
        )
      }
      setSelectedWorkspaces(data)
    } else if (workspaceData && !savedWorkspaces?.length) {
      const data = []
      data.push({ value: workspaceData.id, label: workspaceData.name })
      localStorage.setItem(
        contextData.account.id,
        JSON.stringify({ ...localstorageData, savedWorkspaces: data }),
      )
      setSelectedWorkspaces(data)
    }
  }

  useEffect(() => {
    if (contextData) {
      getDataFromStorage()
    }
  }, [contextData])

  useEffect(() => {
    handleGetWorkspacesData()
  }, [])

  const handleGetWorkspacesData = async () => {
    const workspacesData = await getAllWorkspaces()
    setWorkspaces(workspacesData)
  }

  const handleSelectedWorkspaces = async (val) => {
    const localstorageJson = localStorage.getItem(contextData.account.id)
    let localstorageData = {}
    if (localstorageJson) {
      localstorageData = JSON.parse(localstorageJson)
    }
    setSelectedWorkspaces(val)
    setRefreshData(true)
    localStorage.setItem(
      contextData.account.id,
      JSON.stringify({ ...localstorageData, savedWorkspaces: val }),
    )
  }

  const handleClickedRow = async (rowData) => {
    if (createItem) {
      return
    } else {
      setIsLoading(true)
      setCreateItem(true)
    }

    setClickedRow(rowData)
    const boardName = rowData.find((col) => col?.title === 'Board Name')
    const name = rowData.find((col) => col?.title === 'Name')
    const worker = rowData.find((col) => col?.title === 'Worker')
    const workDate = rowData.find((col) => col?.title === 'Work Date')
    let boardId = ''
    const editDataDefaultValues = []
    if (name.value) {
      const itemColumns = await getItemColumnValues(name.value)
      if (itemColumns.length) {
        const projectIdColumn = itemColumns[0].column_values.find(
          (col) => col.column.title === 'Project ID',
        )
        if (projectIdColumn) {
          editDataDefaultValues.push({
            value: projectIdColumn.text,
            title: 'Project ID',
            type: 'text',
            boardId: boardId,
            columnId: projectIdColumn.id,
            itemId: '',
          })
        }
      }
    }
    if (boardName) {
      editDataDefaultValues.push({
        value: boardName.value || boardName.text,
        title: 'Board Name',
        type: 'text',
        boardId: boardId,
        columnId: 'boardName',
        itemId: '',
        workspaceId: boardName.workspaceId,
      })
    }
    if (name) {
      editDataDefaultValues.push({
        value: name.value || name.text,
        title: 'Name',
        type: 'text',
        boardId: boardId,
        columnId: 'name',
        itemId: name.itemId || contextData.itemId,
        workspaceId: name.workspaceId,
      })
    }
    if (worker) {
      const columnId = columns.find((col) => col.title === 'Worker')
      editDataDefaultValues.push({
        value: worker.value || contextData.user.id,
        title: 'Worker',
        type: 'text',
        boardId: boardId,
        columnId: columnId,
        itemId: '',
      })
    }
    if (workDate) {
      const columnId = columns.find((col) => col.title === 'Work Date')
      editDataDefaultValues.push({
        value: formatDateForInput(new Date()),
        title: 'Work Date',
        type: 'date',
        boardId: boardId,
        columnId: columnId,
        itemId: '',
      })
    }
    const columnId = columns.find((col) => col.title === 'Email')
    editDataDefaultValues.push({
      value: contextData.user.id,
      title: 'Email',
      type: 'email',
      boardId: boardId,
      columnId: columnId || '',
      itemId: '',
    })
    setNewRowData(editDataDefaultValues)
    setIsLoading(false)
  }

  return (
    <div className={`App ${contextData?.theme}-theme`}>
      <div style={{ width: 300 }}>
        <Dropdown
          options={
            workspaces && workspaces.length
              ? workspaces
                  ?.map((workspace) => {
                    return { value: workspace.id, label: workspace.name }
                  })
                  .sort((a, b) => {
                    if (a.label > b.label) return 1
                    if (a.label < b.label) return -1
                    return 0
                  })
              : []
          }
          value={selectedWorkspaces}
          multi
          dropdownMenuWrapperClassName="menu-wrapper-style"
          onChange={(val) => handleSelectedWorkspaces(val)}
          className="dropdown-stories-styles_with-chips menu-style"
          placeholder="Select Workspaces"
        />
      </div>

      {!selectedWorkspaces?.length ? (
        <span>No workspaces selected</span>
      ) : (
        <>
          <div>
            <Filters
              setTotalHours={setTotalHours}
              totalHours={totalHours}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setCurrentWeek={setCurrentWeek}
              currentWeek={currentWeek}
              setCreateItem={setCreateItem}
              isFetching={isFetching}
              isAddingItem={isAddingItem}
              showSuccess={showSuccess}
              setRefreshData={setRefreshData}
            />
          </div>
          <TimesheetItemsSection
            selectedWorkspaces={selectedWorkspaces}
            currentWeek={currentWeek}
            dateRange={dateRange}
            setTotalHours={setTotalHours}
            createItem={createItem}
            setCreateItem={setCreateItem}
            clickedRow={clickedRow}
            setClickedRow={setClickedRow}
            newRowData={newRowData}
            setColumns={setColumns}
            columns={columns}
            initialTimesheetItems={initialTimesheetItems}
            setIsFetching={setIsFetching}
            setIsAddingItem={setIsAddingItem}
            isAddingItem={isAddingItem}
            setShowSuccess={setShowSuccess}
            isLoading={isLoading}
            slug={SLUG}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
          <div style={{ padding: '20px 0' }}>
            <Divider />
          </div>
          <AssignedOnItemsSection
            selectedWorkspaces={selectedWorkspaces}
            handleClickedRow={handleClickedRow}
            dateFilter={dateRange.startDate && dateRange.endDate ? dateRange : currentWeek}
            savedBoardsList={savedBoardsList}
            initialAssignedOnItems={savedAssignedOnItems}
          />
        </>
      )}
    </div>
  )
}

export default App
