import React from "react";

export const TermsOfService = () => {
  return (
    <div style={{ width: "95%", height: "100%", padding: 15 }}>
      <h1>Terms of service</h1>
      <p>
        By using the Master Detail View, you agree to be bound by the following
        terms and conditions (the "Terms of Service"). If you do not agree to
        these terms, please do not use the Master Detail View. License Subject
        to these Terms of Service, we grant you a limited, non-exclusive,
        non-transferable, revocable license to use the Master Detail View solely
        for your personal or internal business purposes. You may not use the
        Master Detail View for any other purpose, including but not limited to
        selling or distributing the Master Detail View or any portion thereof.
        Restrictions You may not reverse engineer, decompile, or disassemble the
        Master Detail View or attempt to access the source code of the Master
        Detail View. You may not modify, adapt, or create derivative works based
        on the Master Detail View. You may not remove or alter any copyright or
        other proprietary notices contained in the Master Detail View. Warranty
        Disclaimer The Master Detail View is provided on an "as is" and "as
        available" basis. We make no warranties, express or implied, including
        but not limited to the implied warranties of merchantability, fitness
        for a particular purpose, and non-infringement. We do not warrant that
        the Master Detail View will be error-free or that access to the Master
        Detail View will be continuous or uninterrupted. Limitation of Liability
        In no event shall we be liable for any damages whatsoever, including but
        not limited to direct, indirect, special, incidental, or consequential
        damages, arising out of or in connection with the use of the Master
        Detail View. Changes to the Master Detail View and these Terms of
        Service We reserve the right to modify or discontinue the Master Detail
        View or these Terms of Service at any time without notice. It is your
        responsibility to check these Terms of Service periodically for changes.
        Your continued use of the Master Detail View after the posting of any
        changes to these Terms of Service constitutes acceptance of those
        changes. Termination We reserve the right to terminate your access to
        the Master Detail View at any time without notice. Upon termination,
        your license to use the Master Detail View will automatically terminate.
        Governing Law These Terms of Service shall be governed by and construed
        in accordance with the laws of the State of Texas, without giving effect
        to any principles of conflicts of law. Miscellaneous These Terms of
        Service constitute the entire agreement between you and us with respect
        to the Master Detail View. If any provision of these Terms of Service is
        found to be invalid or unenforceable, that provision shall be enforced
        to the maximum extent possible, and the remaining provisions shall
        remain in full force and effect. Our failure to enforce any right or
        provision of these Terms of Service shall not be deemed a waiver of such
        right or provision. By using the Master Detail View, you represent and
        warrant that you have read and understood these Terms of Service and
        that you agree to be bound by them. If you have any questions about
        these Terms of Service, please contact us at
        support@capabilitysource.com.
      </p>
    </div>
  );
};
