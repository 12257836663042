export const localStorageKeyValues = {
  savedWorkspaces: 'savedWorkspaces',
  savedTimesheetItems: 'savedTimesheetItems',
  savedAssignedOnItems: 'savedAssignedOnItems',
  savedBoards: 'savedBoards',
}

export const readOnlyColumns = [
  'Subitem',
  'Board Name',
  'Name',
  'Worker',
  'Email',
  'Actual Hours',
  'Item ID',
  'Project ID',
  'Subitems',
  'Timesheet Submission Status',
  'Work Time Tracker',
  'Timesheet',
  'Person',
  'Date',
  'Log Job Time',
  'Log Subitem Time',
]

export const itemDefaultColumns = [
  { title: 'Subitem', type: 'text' },
  { title: 'Worker', type: 'text' },
  { title: 'Email', type: 'email' },
  { title: 'Work Date', type: 'date' },
  { title: 'Actual Hours', type: 'numbers' },
  { title: 'Notes', type: 'long_text' },
  { title: 'Item ID', type: 'text' },
  { title: 'Project ID', type: 'text' },
]

export const defaultStatusColumns = {
  status1: 'Work Role',
  status2: 'Billable/Non-Billable',
}

export const workRoleDefaultOptions = [
  { label: 'Worker' },
  { label: 'Project Manager' },
  { label: 'Designer' },
  { label: 'Lead' },
]

export const billableDefaultOptions = [{ label: 'Billable' }, { label: 'Non-Billable' }]

export const timesheetColumns = [
  { title: 'Board Name', type: 'text', id: 'board_name' },
  { title: 'Name', type: 'text', id: 'name' },
  { title: 'Worker', type: 'worker', id: 'worker' },
  { title: 'Email', type: 'email', id: 'email' },
  { title: 'Work Date', type: 'date', id: 'date' },
  { title: 'Block Work Time', type: 'number', id: 'work_time' },
  { title: 'Work Time Notes', type: 'long_text', id: 'work_notes' },
  { title: defaultStatusColumns.status1, type: 'status', id: 'status1' },
  { title: defaultStatusColumns.status2, type: 'status', id: 'status2' },
]

export const assignedOnColumns = [
  { title: 'Board Name', id: 'board_name' },
  { title: 'Name', id: 'name' },
  { title: 'Timeline', id: 'worker' },
]

export const isNumberReggex = /^-?(\d+)?(\.\d+)?$/
